import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { store } from "./Redux/store/store";
import { Provider } from "react-redux";
import { init as initApm } from "@elastic/apm-rum";
import GlobalToastContainer from "./component/GlobalToastContainer";
import { Box } from "@mui/material";
import { LoaderGif } from "./component/ConstantImagesURL";
import { Hourglass } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const App = lazy(() => import("./App"));

const initializeApm = () => {
  try {
    const apm = initApm({
      serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
      environment: process.env.REACT_APP_APM_ENVIRONMENT,
      serverUrl: process.env.REACT_APP_APM_SERVER_URL,
      serviceVersion: "1.0.0",
      logLevel: "info",
      breakdownMetrics: true,
      transactionSampleRate: 1.0,
    });
    apm.startTransaction("App Load", "app-load");
  } catch (error) {
    console.error("Failed to initialize APM:", error);
  }
};

initializeApm();

const RootComponent = () => {
  const detailedUrlCheck = /\/(annonce-detail|estimation-result)\//.test(
    window.location.pathname
  );

  useEffect(() => {
    if (detailedUrlCheck) {
      localStorage.setItem("detailedUrlCheck", window.location.pathname);
    }
  }, [detailedUrlCheck]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <GlobalToastContainer />
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <img
                  src={LoaderGif}
                  alt="Loading"
                  style={{ width: "80px", height: "80px" }}
                  loading="lazy"
                />
              </Box>
            }
          >
            <App />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
