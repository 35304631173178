const logOutIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}/assets/logOut.svg`;
const simplicitySpeed = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/simplicitySpeed.svg`;
const totalFree = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/totalFree.svg`;
const optimalPrecision = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/optimalPrecision.svg`;
const extendedVisibility = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/extendedVisibility.svg`;
const agencyDetailBanner = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/agency-slider.webp`;
const comparateurDeVente = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurContentPageIcons/comparateurDeVente.svg`;
const comparateurDeLocation = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurContentPageIcons/comparateurDeLocation.svg`;
const comparateurVendu = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurContentPageIcons/comparateurVendu.svg`;
const comparateurAvis = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurContentPageIcons/comparateurAvis.svg`;
const announcesBg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/announcesBg.webp`;
const typeDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/typeDetailPageIcon.svg`;
const surfaceDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/surfaceDetailPageIcon.svg`;
const roomsDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/roomsDetailPageIcon.svg`;
const bathroomsDetailPageIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/typesIcons/bathroomsDetailPageIcon.svg`;
const AppLogoWhite = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/logo-white.svg`;
const AppleStore = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/AppleStore.svg`;
const Googleplay = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/GooglePlay.svg`;
const FbMarketingPrn = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/FbMarketingPrn.svg`;
const GoMarketingPrn = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/footer/GoMarketingPrn.svg`;
const vousEtesDetialPageImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/vousEtesDetialPageImg.svg`;
const contactAgencyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/contactAgencyLogo.svg`;
const avitoLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/avito-logo.webp`;
const marocannonces = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/marocannonces.webp`;
const yakeeyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/yakeey-logo.webp`;
const mubawabLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/mubawab-logo.webp`;
const saroutyLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/sarouty-logo.webp`;
const agenzLogo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/logos/agenz-logo.webp`;
const surfaceIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/surfaceIcon.svg`;
const roomsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/roomsIcon.svg`;
const bathroomsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesSearch/typesIcons/bathroomsIcon.svg`;
const piecesIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/piecesIcon.svg`;
const Surface = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/Surface.svg`;
const Lit = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/Lit.svg`;
const HeartVecter = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/annonces/annoncesDetail/HeartVecter.svg`;
const MaisonsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/BarometreDesPrix/Images/MaisonsIcon.svg`;
const AppartementsIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/BarometreDesPrix/Images/AppartementsIcon.svg`;
const msgTelIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/messageTelBtnIcon.svg`;
const eyeIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/eyeIcon.svg`;
const FaceBookIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/faceBookIcon.svg`;
const InstagramIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/instagramIcon.svg`;
const WhatsAppIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/whatsappIcon.svg`;
const CopyIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/copyIcon.svg`;
const LocationIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/locationIcon.svg`;
const TelephoneIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/telephoneIcon.svg`;
const WappIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/SocialMediaIcons/wappIcon.svg`;
const CestImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/undraw_Design.svg`;
const rightIconFull = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/righticon.svg`;
const PublishImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeLocationCard/PublishImg.svg`;
const surfaceIcon1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeVenteCard/surfaceIcon.svg`;
const roomsIcon1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeVenteCard/roomsIcon.svg`;
const bathroomsIcon1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AnnoncesDeVenteCard/bathroomsIcon.svg`;
const imFolderUpload = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/uploadIcon.svg`;
const riDeleteBin6Line = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/deleteIcon.svg`;
const rightIconFull1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/comparateurDagences/AgenceImmobiliereDetial/AvisClientsCard/righticon.svg`;
const EvaluerImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/EvaluerImg.svg`;
const estimation01 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/estimation01.webp`;
const EstResultAppartmenticon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/EstimationImmobiliere/EstResultAppartmenticon.svg`;
const DecouvrezLePrixImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/Evaluation/DecouvrezLePrixImg.svg`;
const Creche01 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche01.svg`;
const Creche02 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche02.svg`;
const Creche03 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche03.svg`;
const Creche04 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche04.svg`;
const Creche05 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Creche05.svg`;
const Estimervalue1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Estimervalue.svg`;
const evaluationsvg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/evaluation.webp`;
const EvaluerVotreAdresseImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/Evaluation/EvaluerVotreAdresseImg.svg`;
const Bus = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Bus.svg`;
const Metro = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Metro.svg`;
const Train = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Train.svg`;
const Tramway = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/images/Tramway.svg`;
const VousProImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/Estimer/Evaluation/VousProImg.svg`;
const logo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/logo.webp`;
const checkIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepFinal/checkIcon.svg`;
const GalleryIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepThree/galleryIcon.svg`;
const img1 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img1.svg`;
const img2 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img2.svg`;
const img3 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img3.svg`;
const img4 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img4.svg`;
const img5 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img5.svg`;
const img6 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img6.svg`;
const img7 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img7.svg`;
const img8 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img8.svg`;
const img9 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img9.svg`;
const img10 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img10.svg`;
const img11 = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/cardIcons/img11.svg`;
const Cube = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/MesAnnonces/StepTwo/AjouterUneVisiteVirtuelle/cube.svg`;

const peopleIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/peopleIcon.svg`;
const PlusIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/PlusIcon.svg`;
const LoaderGif = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/loader.svg`;
const loginpageBg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/loginpage-bg.webp`;
const ArrowRightFooter = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/ArrowRightFooter.svg`;
const validationIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/signIn/validationIcon.svg`;
const facebookIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/signIn/facebookIcon.svg`;
const parCuri = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/parCuri.svg`;
const parTel = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/parTel.svg`;
const parunter = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/parunter.svg`;
const Estimervalue = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/contactPage/contactPage.webp`;
const iconOne = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconOne.svg`;
const iconTwo = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconTwo.svg`;
const iconThree = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconThree.svg`;
const iconFour = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/announcesContentPageIcons/iconFour.svg`;
const iconFive = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/estimationContentPageIcons/iconFive.svg`;
const iconSix = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/estimationContentPageIcons/iconSix.svg`;
const telephoneIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/telephoneIcon.svg`;
const imgBg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/annuaireBg.webp`;
const MiseEnRelation = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/MiseEnRelation.svg`;
const AvisInfalsifiables = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/AvisInfalsifiables.svg`;
const PriseDeRendezvous = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/comparateurDagences/PriseDeRendezvous.svg`;
const BarometreDuImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/BarometreDuImg.webp`;
const ComparezPlusImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/ComparezPlusImg.webp`;
const LouerIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/LouerIcon.webp`;
const AcheterIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/AcheterIcon.webp`;
const CoursIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/CoursIcon.webp`;
const AssuranceIcon = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/AvezVousEnvie/AssuranceIcon.webp`;
const EstimerLlaValeurImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/EstimerLlaValeurmg.webp`;
const EstimezLeImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/EstimezLeImg.webp`;
const UneExperienceImg = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}assets/images/home/UneExperience.webp`;
const regions_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/regions.json`;
const provinces_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/provinces.json`;
const communes_geojson = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}geojson/citytest.json`;
const AgentsImmobilierContentData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/agentsImmobilierContentData.json`;
const AgentsImmobilierFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/agentsImmobilierFaqData.json`;
const AgentsImmobilierTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/agentsImmobilierTestimonialData.json`;
const AnnouncesContentData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/announcesContentData.json`;
const AnnouncesFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/announcesFaqData.json`;
const AnnouncesTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/announcesTestimonialData.json`;
const EstimationContentData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/estimationContentData.json`;
const EstimationFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/estimationFaqData.json`;
const EstimationTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/estimationTestimonialData.json`;
const HomeFaqData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/homeFaqData.json`;
const HomeTestimonialData = `${process.env.REACT_APP_AWS_CDN_URL_STATIC}json/homeTestimonialData.json`;

export {
  AgentsImmobilierContentData,
  AgentsImmobilierFaqData,
  AgentsImmobilierTestimonialData,
  AnnouncesContentData,
  AnnouncesFaqData,
  AnnouncesTestimonialData,
  EstimationContentData,
  EstimationFaqData,
  EstimationTestimonialData,
  HomeFaqData,
  HomeTestimonialData,
  provinces_geojson,
  communes_geojson,
  regions_geojson,
  logOutIcon,
  simplicitySpeed,
  totalFree,
  optimalPrecision,
  extendedVisibility,
  agencyDetailBanner,
  comparateurDeVente,
  comparateurDeLocation,
  comparateurVendu,
  comparateurAvis,
  announcesBg,
  peopleIcon,
  PlusIcon,
  LoaderGif,
  loginpageBg,
  ArrowRightFooter,
  validationIcon,
  facebookIcon,
  parCuri,
  parTel,
  parunter,
  Estimervalue,
  iconOne,
  iconTwo,
  iconThree,
  iconFour,
  iconFive,
  iconSix,
  telephoneIcon,
  imgBg,
  MiseEnRelation,
  AvisInfalsifiables,
  PriseDeRendezvous,
  BarometreDuImg,
  ComparezPlusImg,
  LouerIcon,
  AcheterIcon,
  CoursIcon,
  AssuranceIcon,
  EstimerLlaValeurImg,
  EstimezLeImg,
  UneExperienceImg,
  typeDetailPageIcon,
  surfaceDetailPageIcon,
  roomsDetailPageIcon,
  bathroomsDetailPageIcon,
  AppLogoWhite,
  AppleStore,
  Googleplay,
  FbMarketingPrn,
  GoMarketingPrn,
  vousEtesDetialPageImg,
  contactAgencyLogo,
  avitoLogo,
  marocannonces,
  yakeeyLogo,
  mubawabLogo,
  saroutyLogo,
  agenzLogo,
  surfaceIcon,
  roomsIcon,
  bathroomsIcon,
  piecesIcon,
  Surface,
  Lit,
  HeartVecter,
  MaisonsIcon,
  AppartementsIcon,
  msgTelIcon,
  eyeIcon,
  FaceBookIcon,
  InstagramIcon,
  WhatsAppIcon,
  CopyIcon,
  LocationIcon,
  TelephoneIcon,
  WappIcon,
  CestImg,
  rightIconFull,
  PublishImg,
  surfaceIcon1,
  roomsIcon1,
  bathroomsIcon1,
  imFolderUpload,
  riDeleteBin6Line,
  rightIconFull1,
  EvaluerImg,
  estimation01,
  EstResultAppartmenticon,
  DecouvrezLePrixImg,
  Creche01,
  Creche02,
  Creche03,
  Creche04,
  Creche05,
  Estimervalue1,
  evaluationsvg,
  EvaluerVotreAdresseImg,
  Bus,
  Metro,
  Train,
  Tramway,
  VousProImg,
  logo,
  checkIcon,
  GalleryIcon,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  Cube,
};
